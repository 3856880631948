import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, BaseComponent } from "@impacgroup/angular-baselib";
import { User } from "@impacgroup/angular-oauth-base";
import { CamerasService } from "./cameras.service";
import { TranslateService } from "@ngx-translate/core";
import { getDefaultCameraSettings } from "../../api-models/ICameraSettings";
import { Observable, Observer, of, Subject } from "rxjs";
import { map, debounceTime, distinctUntilChanged, switchMap, catchError } from "rxjs/operators";

import { latinize, TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Imei } from "../../api-models/Imei";
import { ImeisService } from "../imeis/imeis.service";
import { CameraCreateOrReplaceOutputDTO, UserCameraListResponseDTO } from "./cameras.service.dto";
import { ImeiStatus } from "./cameras.service.dto";
import { plainToInstance } from "class-transformer";
import { AvailableProductTypes, ProductType, ProductTypeKey } from "@impacgroup/doerr-wildkamera-api-shared";
import { CameraCreateOrReplace4GPro, CameraDetail4GProHelper } from "./cameradetail4gpro.viewmodel";
import { Cameras4GProService } from "./cameras4gpro.service";

enum LoadingStatus {
    done = "done",
    error = "error",
    loading = "loading"
}

@Component({
    selector: "app-cameracreate",
    templateUrl: "./cameracreate.component.html",
    styleUrls: ["cameracreate.component.scss"]
})
export class CameracreateComponent extends BaseComponent implements OnInit {
    public debounceTime = 300;

    public LoadingStatus = LoadingStatus;

    public userQuery?: string;
    public userQueryObserver?: Observer<string | undefined>;
    public userSuggestions?: Observable<string[]>;
    public userSuggLoadingStatus = LoadingStatus.done;

    public user?: User;

    public imeiQuery?: string;
    public imeiQuerySubject: Subject<string> = new Subject<string>();
    public imeiInfoLoadingStatus = LoadingStatus.done;
    public imeiStatus?: ImeiStatus;
    public imeiProductType?: ProductTypeKey;
    public imeiValid = false;
    public imeiInvalid = false;
    public stolenCameraInfo: any = null;

    public cameraNewOrRepl: "new" | "repl" | null = null;

    public camera4GV1V2: CameraCreateOrReplaceOutputDTO | null = null;
    public camera4GPro: CameraCreateOrReplace4GPro | null = null;

    private newCamera4GV1V2: CameraCreateOrReplaceOutputDTO;
    private newCamera4GPro: CameraCreateOrReplace4GPro;

    private replacementCamera4GV1V2: CameraCreateOrReplaceOutputDTO | null = null;
    private replacementCamera4GPro: CameraCreateOrReplace4GPro | null = null;

    public userCameras: UserCameraListResponseDTO[] | null = null;
    public productFilteredCameras: UserCameraListResponseDTO[] | null = null;
    public selectedUsersCamera: UserCameraListResponseDTO | null = null;

    public productTypes: ProductType[] = AvailableProductTypes;
    public newImeiProductType?: ProductTypeKey;

    constructor(
        private route: ActivatedRoute,
        private camerasService: CamerasService,
        private cameras4GProService: Cameras4GProService,
        private imeisService: ImeisService,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService,
        private _location: Location
    ) {
        super();

        this.newCamera4GV1V2 = this.createDefaultCamera4GV1V2();
        this.newCamera4GPro = this.createDefaultCamera4GPro();

        this.userSuggestions = new Observable((observer: Observer<string | undefined>) => {
            this.userQueryObserver = observer;
            observer.next(this.userQuery);
        }).pipe(
            switchMap((query: string) => {
                if (query && query.length > 2) {
                    this.userSuggLoadingStatus = LoadingStatus.loading;

                    return this.camerasService
                        .userSuggestions({ search: query })
                        .pipe(
                            map((data: any) => {
                                this.userSuggLoadingStatus = LoadingStatus.done;
                                return (data && data.list) || [];
                            })
                        )
                        .pipe(
                            catchError((err) => {
                                this.userSuggLoadingStatus = LoadingStatus.error;
                                return of([]);
                            })
                        );
                }

                this.userSuggLoadingStatus = LoadingStatus.done;

                return of([]);
            })
        );

        if (this.user) {
            this.userQuery = `${this.user.firstname} ${this.user.lastname}`.trim();
            this.getUserCameras();
        }

        if (this.imeiQuery) {
            this.imeiQueryChanged();
        }

        this.imeiQuerySubject.pipe(debounceTime(this.debounceTime), distinctUntilChanged()).subscribe((query) => {
            this.imeiQuery = query;
            this.imeiQueryChanged();
        });
    }

    userQueryChanged() {
        this.userQueryObserver?.next(this.userQuery);
    }

    clearUserQuery() {
        this.userQuery = undefined;
        this.user = undefined;
        this.userSuggLoadingStatus = LoadingStatus.done;
    }

    userSuggestionOnBlur() {}
    userSuggestionOnNoResults() {}
    userSuggestionOnSelect(match: TypeaheadMatch) {
        this.user = match.item;
        this.userQuery = `${this.user?.firstname} ${this.user?.lastname}`.trim();
        this.getUserCameras();
    }

    getUserCameras() {
        if (this.user === null) {
            this.userCameras = null;
            this.refreshFilteredCameras();
        }

        if (!this.user?._id) {
            return;
        }

        this.subscriptions.push(
            this.camerasService.getUserCameras(this.user._id).subscribe({
                next: (userCameras: any) => {
                    this.userCameras = userCameras;
                },
                complete: () => {
                    this.refreshFilteredCameras();
                    this.refreshCameraChoice();
                }
            })
        );
    }

    imeiQueryChanged() {
        if (this.imeiQuery && this.imeiQuery.length === 15) {
            this.imeiInfoLoadingStatus = LoadingStatus.loading;

            this.camerasService.getImeiInformations(this.imeiQuery).subscribe({
                next: (value) => {
                    this.imeiInfoLoadingStatus = LoadingStatus.done;

                    this.imeiValid = false;
                    this.imeiInvalid = false;
                    this.imeiStatus = value.status;
                    this.imeiProductType = value.productType;

                    switch (this.imeiStatus) {
                        case "imeiDoesNotExist":
                        case "imeiInvalid":
                        case "cameraStolen":
                        case "imeiAssigned":
                            this.imeiInvalid = true;
                            break;
                        case "imeiExists":
                            this.imeiValid = true;
                            break;
                        default:
                    }

                    this.refreshFilteredCameras();
                    this.refreshCameraChoice();
                },
                error: (error) => {
                    this.imeiInfoLoadingStatus = LoadingStatus.error;
                    this.imeiValid = false;
                    this.imeiInvalid = false;
                    this.imeiStatus = undefined;
                    this.imeiProductType = undefined;

                    this.refreshFilteredCameras();
                    this.refreshCameraChoice();
                }
            });
        } else {
            this.imeiValid = false;
            this.imeiInvalid = false;
            this.imeiStatus = undefined;
        }
    }

    clearImeiQuery() {
        this.imeiQuery = undefined;
        this.imeiQueryChanged();
    }

    newCameraChoosen(_?: any) {
        if (this.is4GV1V2ProductType(this.imeiProductType)) {
            this.newCamera4GV1V2.productType = this.imeiProductType;
            this.camera4GV1V2 = this.newCamera4GV1V2;
        }

        if (this.is4GProProductType(this.imeiProductType)) {
            this.newCamera4GPro.productType = this.imeiProductType;
            this.camera4GPro = this.newCamera4GPro;
        }
    }

    private is4GV1V2ProductType(productType?: string) {
        return !!productType && ["cloud_4g_v1", "cloud_4g_v2"].includes(productType);
    }

    private is4GProProductType(productType?: string) {
        return productType === "cloud_4g_pro" || productType === "cloud_4g_pro_wa" || productType === "cloud_4g_pro_rc" || productType === "cloud_4g_pro_rc_wa";
    }

    replCameraChoosen(_?: any) {
        let replacementCamFound = false;

        if (this.is4GV1V2ProductType(this.imeiProductType) && this.selectedUsersCamera && this.selectedUsersCamera._id === this.replacementCamera4GV1V2?._id) {
            replacementCamFound = true;
            this.camera4GV1V2 = this.replacementCamera4GV1V2;
        }

        if (this.is4GProProductType(this.imeiProductType) && this.selectedUsersCamera && this.selectedUsersCamera._id === this.replacementCamera4GPro?._id) {
            replacementCamFound = true;
            this.camera4GPro = this.replacementCamera4GPro;
        }

        if (!replacementCamFound) {
            this.camera4GV1V2 = null;
            this.camera4GPro = null;
        }
    }

    cameraToReplaceSelected(_?: any) {
        if (!this.selectedUsersCamera?.productType || !this.selectedUsersCamera?._id) {
            return;
        }

        if (this.is4GV1V2ProductType(this.selectedUsersCamera.productType)) {
            this.subscriptions.push(
                this.camerasService.cameradetail({ type: this.selectedUsersCamera.productType, id: this.selectedUsersCamera._id }).subscribe((camera) => {
                    this.replacementCamera4GV1V2 = plainToInstance(CameraCreateOrReplaceOutputDTO, { ...camera, userId: camera?.user?._id });
                    this.camera4GV1V2 = this.replacementCamera4GV1V2;
                    this.camera4GPro = null;
                })
            );
        }

        if (this.is4GProProductType(this.selectedUsersCamera.productType)) {
            this.subscriptions.push(
                this.cameras4GProService.read({ id: this.selectedUsersCamera._id, productType: this.selectedUsersCamera.productType }).subscribe((camera) => {
                    this.replacementCamera4GPro = plainToInstance(CameraCreateOrReplace4GPro, { ...camera, userId: camera?.owner?._id });
                    this.camera4GPro = this.replacementCamera4GPro;
                    this.camera4GV1V2 = null;
                })
            );
        }
    }

    ngOnInit(): void {}

    save() {
        if (this.cameraNewOrRepl === "repl") {
            const cameraExistsInFilteredCameras = this.productFilteredCameras?.find((camera) => camera._id === this.selectedUsersCamera?._id);

            if (!cameraExistsInFilteredCameras) {
                return;
            }

            if (!this.isAllowedProductTypeToReplace(this.imeiProductType, this.selectedUsersCamera?.productType)) {
                return;
            }
        }

        let createOrReplaceObs;

        if (this.camera4GV1V2) {
            this.camera4GV1V2.imei = this.imeiQuery;
            this.camera4GV1V2.productType = this.imeiProductType;
            this.camera4GV1V2.userId = this.user?._id;

            createOrReplaceObs = this.camerasService.createOrReplaceCamera(this.camera4GV1V2);
        }

        if (this.camera4GPro) {
            this.camera4GPro.imei = this.imeiQuery;
            this.camera4GPro.productType = this.imeiProductType;
            this.camera4GPro.userId = this.user?._id;

            createOrReplaceObs = this.cameras4GProService.createOrReplaceCamera(this.camera4GPro, this.camera4GPro.productType!);
        }

        if (!createOrReplaceObs) {
            return;
        }

        const createOrReplaceSub = createOrReplaceObs.subscribe(() => {
            const successTextId = "cameras.detail.add.camera.success." + (this.cameraNewOrRepl === "new" ? "add" : "replace");

            this.alertService.addSuccess(this.translateService.instant(successTextId));

            this.router.navigate(["../"], { relativeTo: this.route });
        });

        this.subscriptions.push(createOrReplaceSub);
    }

    addImei() {
        if (!this.newImeiProductType) {
            return;
        }

        const newImei = new Imei();
        newImei.code = this.imeiQuery ?? "";
        newImei.productType = this.newImeiProductType;

        this.subscriptions.push(
            this.imeisService.addimei(newImei).subscribe((result) => {
                this.alertService.addSuccess(this.translateService.instant("imeis.detail.add.success"));
                this.imeiQueryChanged();
            })
        );
    }

    highlight(match: TypeaheadMatch, query: string[] | string): string {
        const user = match.item;
        let name = "";
        let mail = "";

        if (user.firstname) {
            name += `${user.firstname} `;
        }

        if (user.lastname) {
            name += `${user.lastname} `;
        }

        if (user.email) {
            mail += `${user.email} `;
        }

        let itemStr: string = (name + mail).trim();
        let itemStrHelper = latinize(itemStr).toLowerCase();
        let startIdx: number;
        let tokenLen: number;
        // Replaces the capture string with the same string inside of a "strong" tag
        if (typeof query === "object") {
            const queryLen: number = query.length;
            for (let i = 0; i < queryLen; i += 1) {
                // query[i] is already latinized and lower case
                startIdx = itemStrHelper.indexOf(query[i]);
                tokenLen = query[i].length;
                if (startIdx >= 0 && tokenLen > 0) {
                    itemStr = `${itemStr.substring(0, startIdx)}<strong>${itemStr.substring(startIdx, startIdx + tokenLen)}</strong>` + `${itemStr.substring(startIdx + tokenLen)}`;
                    itemStrHelper = `${itemStrHelper.substring(0, startIdx)}        ${" ".repeat(tokenLen)}         ` + `${itemStrHelper.substring(startIdx + tokenLen)}`;
                }
            }
        } else if (query) {
            // query is already latinized and lower case
            startIdx = itemStrHelper.indexOf(query);
            tokenLen = query.length;
            if (startIdx >= 0 && tokenLen > 0) {
                itemStr = `${itemStr.substring(0, startIdx)}<strong>${itemStr.substring(startIdx, startIdx + tokenLen)}</strong>` + `${itemStr.substring(startIdx + tokenLen)}`;
            }
        }

        return itemStr;
    }

    back() {
        this._location.back();
    }

    isAllowedProductTypeToReplace(sourceProductType: ProductTypeKey | undefined, targetProductType: ProductTypeKey | undefined): Boolean {
        let allowedProductTypes = [sourceProductType];

        // Allow replacing cameras with the same "parent" type
        switch (sourceProductType) {
            case "cloud_4g_pro":
                allowedProductTypes = ["cloud_4g_pro", "cloud_4g_pro_wa"];
                break;
            case "cloud_4g_pro_wa":
                allowedProductTypes = ["cloud_4g_pro", "cloud_4g_pro_wa"];
                break;
            case "cloud_4g_pro_rc":
                allowedProductTypes = ["cloud_4g_pro_rc", "cloud_4g_pro_rc_wa"];
                break;
            case "cloud_4g_pro_rc_wa":
                allowedProductTypes = ["cloud_4g_pro_rc", "cloud_4g_pro_rc_wa"];
                break;
        }

        return allowedProductTypes.includes(targetProductType);
    }

    private refreshFilteredCameras() {
        this.productFilteredCameras = this.userCameras?.filter((camera) => this.isAllowedProductTypeToReplace(this.imeiProductType, camera.productType)) ?? null;
    }

    private refreshCameraChoice() {
        if ((this.productFilteredCameras?.length ?? 0) === 0) {
            this.cameraNewOrRepl = "new";
            this.newCameraChoosen();
        } else {
            this.cameraNewOrRepl = "repl";
            this.replCameraChoosen();
        }
    }

    private createDefaultCamera4GV1V2(): CameraCreateOrReplaceOutputDTO {
        const camera = new CameraCreateOrReplaceOutputDTO();

        camera.settings = getDefaultCameraSettings();
        camera.doNotSendHighRes = false;
        camera.gpsActive = false;

        camera.version = 1;

        return camera;
    }

    private createDefaultCamera4GPro(): CameraCreateOrReplace4GPro {
        return { proSettings: CameraDetail4GProHelper.defaultSettings() };
    }
}
