import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionService } from "@impacgroup/angular-oauth-base";
import { AlertService, BaseComponent, ConfirmDialogComponent } from "@impacgroup/angular-baselib";
import { CamerasService } from "../cameras.service";
import { TranslateService } from "@ngx-translate/core";
import { allCountries } from "src/app/api-models/Camera";
import { BsModalService } from "ngx-bootstrap/modal";
import { CameraCreateOrReplace4GPro, CameraDetail4GPro } from "../cameradetail4gpro.viewmodel";
import {
    getAvailableProBatteryTypes,
    ProBatteryType,
    ProBatteryTypeArray,
    ProCameraMode,
    ProCameraModeArray,
    ProFlashPower,
    ProFlashPowerArray,
    ProImageSize,
    ProImageSizeArray,
    ProMaxNumPerDay,
    ProMaxNumPerDayArray,
    ProMultiShot,
    ProMultiShotArray,
    ProMultiShotInterval,
    ProMultiShotIntervalArray,
    ProPIRSensitivity,
    ProPIRSensitivityArray,
    ProRemoteControl,
    ProRemoteControlArray,
    ProSecurityMode,
    ProSecurityModeArray,
    ProSendingMode,
    ProSendingModeArray,
    ProVideoLength,
    ProVideoLengthArray,
    ProVideoSize,
    ProVideoSizeArray
} from "@impacgroup/doerr-wildkamera-api-shared";
import { NgForm } from "@angular/forms";
import { Cameras4GProService } from "../cameras4gpro.service";

@Component({
    selector: "app-camera-data-and-settings-4g-pro",
    templateUrl: "./cameradataandsettings4gpro.component.html",
    styleUrls: ["cameradataandsettings4gpro.component.scss"]
})
export class CameraDataAndSettings4GProComponent extends BaseComponent implements OnInit, OnChanges {
    @Input()
    public camera?: CameraDetail4GPro | CameraCreateOrReplace4GPro;

    @ViewChild("f", { static: false }) form?: NgForm;

    /**
     * Show reconnect button
     */
    @Input()
    public showReconnectButton: boolean = true;

    /**
     * Show stolen button, if permissions allows it
     */
    @Input()
    public showStolenButtons: boolean = true;

    /**
     * Will be emitted after reconnection command send successful
     */
    @Output()
    public reconnectSendSuccessful = new EventEmitter<number>();

    /**
     * Will be emitted after save button was clicked and validation was successful
     */
    @Output()
    public saveClickedAndValid = new EventEmitter<number>();

    // basedata edit permission
    public mayEditBasedata = false;

    // Settings - selectable values
    cameraModeOptions: ProCameraMode[] = ProCameraModeArray;
    sendingModeOptions: ProSendingMode[] = ProSendingModeArray;
    securityModeOptions: ProSecurityMode[] = ProSecurityModeArray;
    batteryTypeOptions: ProBatteryType[] = ProBatteryTypeArray;
    remoteControlOptions: ProRemoteControl[] = ProRemoteControlArray;
    countries = allCountries;
    timezoneIdOptions: string[] = [];

    imageSizeOptions: ProImageSize[] = ProImageSizeArray;
    multiShotOptions: ProMultiShot[] = ProMultiShotArray;
    multiShotIntervalOptions: ProMultiShotInterval[] = ProMultiShotIntervalArray;
    maxNumPerDayMinValue = 1;
    maxNumPerDayMaxValue = 99;
    maxNumPerDayOffValue = this.maxNumPerDayMinValue - 1;
    maxNumPerDayArray: ProMaxNumPerDay[] = ProMaxNumPerDayArray;

    flashPowerOptions: ProFlashPower[] = ProFlashPowerArray;

    pirActive = true;
    pirArray: ProPIRSensitivity[] = ProPIRSensitivityArray;

    videoSizeOptions: ProVideoSize[] = ProVideoSizeArray;
    videoLengthMinValue = 5;
    videoLengthMaxValue = 59;
    videoLength: ProVideoLength[] = ProVideoLengthArray;

    regExpPatternSeconds = "[0-5][0-9]|^[0-9]$";
    regExpPatternSeconds5LowerLimit = "[1-5][0-9]|[0][5-9]|^[5-9]$";

    timeLapseSSPattern = this.regExpPatternSeconds;
    delaySSPattern = this.regExpPatternSeconds;

    constructor(
        private route: ActivatedRoute,
        private camerasService: CamerasService,
        private cameras4GProService: Cameras4GProService,
        private router: Router,
        private alertService: AlertService,
        private modalService: BsModalService,
        private translateService: TranslateService,
        private permissionService: PermissionService,
        private cdr: ChangeDetectorRef
    ) {
        super();

        this.mayEditBasedata = this.permissionService.hasPermission("CAMERAS.EDIT.BASEDATA");
    }
    ngOnChanges(changes: SimpleChanges): void {
        if ("productType" in changes && changes["productType"].currentValue !== changes["productType"].previousValue) {
            this.loadTimezoneList();
            this.batteryTypeOptions = getAvailableProBatteryTypes(this.camera?.productType);
        }
    }

    ngOnInit(): void {
        this.loadTimezoneList();
        this.batteryTypeOptions = getAvailableProBatteryTypes(this.camera?.productType);
    }

    private loadTimezoneList() {
        const timezoneIdsSub = this.cameras4GProService.timezonelist(this.camera?.productType ?? "cloud_4g_pro").subscribe((timezoneIds) => {
            this.timezoneIdOptions = timezoneIds;
        });

        this.subscriptions.push(timezoneIdsSub);
    }

    addStolenCamera() {
        if (this.camera?._id) {
            this.router.navigate(["../../../stolencameras/add/", this.camera._id], { relativeTo: this.route });
        }
    }

    editStolenCamera() {
        if (!this.camera?.stolen) {
            return;
        }
        this.router.navigate(["../../../stolencameras/", this.camera.stolen], { relativeTo: this.route });
    }

    // Needed for toggles, if the result of the toggle is used elsewhere (for validation)
    modelChanged() {
        this.cdr.detectChanges();
    }

    reconnectCamera() {
        const initialState = {
            title: this.translateService.instant("cameras.detail.edit.reconnect.title"),
            message: this.translateService.instant("cameras.detail.edit.reconnect.message"),
            closeBtnName: this.translateService.instant("global.buttons.close"),
            confirmBtnName: this.translateService.instant("global.buttons.ok")
        };
        const bsModalRef = this.modalService.show(ConfirmDialogComponent, { initialState });
        if (bsModalRef.content) {
            bsModalRef.content.confirmCallback = () => {
                if (!this.camera?.productType || !this.camera?._id) {
                    return;
                }

                this.subscriptions.push(
                    this.camerasService.reconnectCamera({ type: this.camera.productType, id: this.camera._id }).subscribe((result) => {
                        this.alertService.addSuccess(this.translateService.instant("cameras.detail.edit.reconnect.success"));

                        this.reconnectSendSuccessful.emit();
                    })
                );
            };
        }
    }

    showAddStolenCameraButton() {
        return this.showStolenButtons && this.permissionService.hasPermission("STOLENCAMERAS.ADD") && !this.camera?.stolen;
    }

    showEditStolenCameraButton() {
        return this.showStolenButtons && this.permissionService.hasPermission("STOLENCAMERAS.EDIT") && this.camera?.stolen;
    }

    save() {
        this.saveClickedAndValid.emit();
    }

    public updateTimeLapseSSPattern() {
        if ((this.camera?.proSettings?.timeLapseHH && parseInt(this.camera.proSettings.timeLapseHH, 10) > 0) || (this.camera?.proSettings?.timeLapseMM && parseInt(this.camera.proSettings.timeLapseMM, 10) > 0)) {
            this.timeLapseSSPattern = this.regExpPatternSeconds;
        } else {
            this.timeLapseSSPattern = this.regExpPatternSeconds5LowerLimit;
        }
        this.cdr.detectChanges();
    }

    public updateDelaySSPattern() {
        if ((this.camera?.proSettings?.delayHH && parseInt(this.camera.proSettings.delayHH, 10) > 0) || (this.camera?.proSettings?.delayMM && parseInt(this.camera.proSettings.delayMM, 10) > 0)) {
            this.delaySSPattern = this.regExpPatternSeconds;
        } else {
            this.delaySSPattern = this.regExpPatternSeconds5LowerLimit;
        }
        this.cdr.detectChanges();
    }
}
